import React, {useState, useRef} from 'react'
import emailjs from 'emailjs-com';

function Contact() {
    const [isSent, setIsSent] = useState(false)
    const nameInput = useRef(null)
    const nameMissing = useRef(null)
    const emailInput = useRef(null)
    const emailError = useRef(null)
    const emailMissing = useRef(null)

    function sendEmail(e) {
        e.preventDefault();
        removeErrorMessage()
        if(!formValidEh()) return
        
        emailjs.sendForm('portfolio-id', 'template_tunpqno', e.target, 'user_j7yM9LMciGWcJOyo8BeO9')
        .then((result) => {
            console.log(result.text);
            setIsSent(true)
        }, (error) => {
            console.log(error.text);
        });
    }

    function formValidEh(){
        let validEh = true
        if(!nameInput.current.value){
            handleNameMissing()
            validEh = false
        }
        if(!emailInput.current.value){
            handleEmailMissing()
            validEh = false
        } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailInput.current.value)){
            handleEmailError()
            validEh = false
        }
        return validEh
    }

    function removeErrorMessage() {
        emailInput.current.classList.remove('border-2')
        emailInput.current.classList.remove('border-solid')
        emailInput.current.classList.remove('border-red-600')
        nameInput.current.classList.remove('border-2')
        nameInput.current.classList.remove('border-solid')
        nameInput.current.classList.remove('border-red-600')
        emailError.current.classList.add('hidden')
        emailMissing.current.classList.add('hidden')
        nameMissing.current.classList.add('hidden')
    }

    function handleEmailError() {
        console.log('Incorrect Email')
        emailError.current.classList.remove('hidden')
        // emailInput.current.classList.remove('focus:border-b-2')
        // emailInput.current.classList.remove('focus:border-blue-700')
        emailInput.current.classList.add('border-2')
        emailInput.current.classList.add('border-solid')
        emailInput.current.classList.add('border-red-600')
    }

    function handleEmailMissing(){
        console.log('no email');
        emailMissing.current.classList.remove('hidden')
        // emailInput.current.classList.remove('focus:border-b-2')
        // emailInput.current.classList.remove('focus:border-blue-700')
        emailInput.current.classList.add('border-2')
        emailInput.current.classList.add('border-solid')
        emailInput.current.classList.add('border-red-600')
    }

    function handleNameMissing() {
        console.log('no name');
        nameMissing.current.classList.remove('hidden')
        // nameInput.current.classList.remove('focus:border-b-2')
        // nameInput.current.classList.remove('focus:border-blue-700')
        nameInput.current.classList.add('border-2')
        nameInput.current.classList.add('border-solid')
        nameInput.current.classList.add('border-red-600')
    }

    return (
        <div id="contact" className="flex flex-col items-center justify-evenly bg-gradient-to-t from-black to-transparent w-full h-auto">
            <div className="flex flex-col items-center justify-center w-4/5 md:w-2/3 h-auto">
                <div className="text-2xl font-bold mb-2">
                    Contact
                </div>
                <div className="w-10/12 sm:w-4/5 md:w-4/6 lg:w-1/2">
                    {/* <a className="w-full h-auto text-center bg-grey-500" href="https://calendly.com/ashwinarora/30min" target="_blank">
                        <div>
                            Click Here to
                        </div>
                        <div>
                            Schedule a Meeting
                        </div>
                    </a> */}
                    <div className="flex justify-center items-center text-lg sm:text-2xl rounded-lg cursor-pointer bg-orange-600 hover:bg-yellow-500 focus:bg-blue-700 w-full h-16" onClick={() => {window.open("https://calendly.com/ashwinarora/30min");}}>
                        <div className="inline-block align-middle">
                            Schedule a Meeting
                            <svg className="inline w-6 h-6 sm:w-8 sm:h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /></svg>
                        </div>
                    </div>
                    {
                        isSent
                        ?
                        <div className="flex flex-col items-center justify-center mb-8 w-full h-2/3">
                            {/* <svg className="w-1/2 h-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> */}
                            <svg className="h-auto w-1/3 rounded-full border-8 my-4 border-solid border-green-500 text-green-500 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                            <div>
                                Your message have been sent successfully.
                            </div>
                        </div>
                        :
                        <form className="flex flex-col items-start justi py-4 w-full" onSubmit={sendEmail}>
                            <label className="py-1 mt-1">Name</label>
                            <input className="w-full bg-gray-400 px-2 py-1 outline-none focus:border-0 focus:ring focus:ring-blue-500 focus:bg-blue-50 rounded-md text-gray-700" type="text" name="user_name" ref={nameInput} />
                            <p className="text-xs text-red-800 font-bold hidden" ref={nameMissing}>This Field is Required</p>
                            <label className="py-1 mt-1">E-mail</label>
                            <input className="w-full bg-gray-400 px-2 py-1 outline-none focus:border-0 focus:ring focus:ring-blue-500 focus:bg-blue-50 rounded-md text-gray-700" type="text" name="user_email" ref={emailInput} />
                            <p className="text-xs text-red-800 font-bold hidden" ref={emailError}>Incorrect E-mail</p>
                            <p className="text-xs text-red-800 font-bold hidden" ref={emailMissing}>This Field is Required</p>
                            <label className="py-1 mt-1">Message</label>
                            <textarea  className="w-full bg-gray-400 px-2 py-1 outline-none focus:border-0 focus:ring focus:ring-blue-500 focus:bg-blue-50 rounded-md text-gray-700" name="user_message" />
                            <input className="text-center w-full p-1 text-xl bg-orange-600 hover:bg-yellow-500 cursor-pointer rounded-md mt-2 active:bg-blue-700" type="submit" value="Send" />
                        </form>
                    }
                </div>
            </div>
        </div>
    )
}

export default Contact
