import et1 from '../assets/et1.jpg'
import et2 from '../assets/et2.jpg'
import et3 from '../assets/et3.jpg'
import et4 from '../assets/et4.jpg'
import et5 from '../assets/et5.jpg'
import sn1 from '../assets/sn-01.jpg'
import sn2 from '../assets/sn-02.jpg'
import sn3 from '../assets/sn-03.jpg'
import sn4 from '../assets/sn-04.jpg'
import sn5 from '../assets/sn-05.jpg'
import h1 from '../assets/hr1.jpg'
import h2 from '../assets/hr2.jpg'
import h3 from '../assets/hr3.jpg'
import h4 from '../assets/hr4.jpg'
import h5 from '../assets/hr5.jpg'

export const project01 = {
    name: 'Ethereum TicTacToe',
    images: [et1,et2,et3,et4,et5],
    description: `A real-time multiplayer TicTacToe game where two players can bet ETH and the winner take all. A state channel is created between the two players to enable 'Off-Chain' exchange of messages. These messages are signed by the player's private key.`,
    features: ['Multiplayer', 'Real Time', 'Instant Payouts', 'State Channel', 'Off-Chain Gameplay'],
    tools: ['Socket.io', 'Ethers.js', 'Node', 'Express', 'DOM', 'Bootstrap', 'Solidity'],
    // youtube: 'https://www.youtube.com/embed/zccVz3-4Mho?start=',
    videoId: 'UmSzmoAK5jM',
    timestamps: [
        {
            name: 'Intro',
            timestamp: 0
        }, 
        {
            name: 'Initial Account Balance',
            timestamp: 5
        },
        {
            name: 'New Game Created',
            timestamp: 13
        },
        {
            name: 'Game Joined',
            timestamp: 26
        },
        {
            name: 'Game Play Started',
            timestamp: 44
        },
        {
            name: 'Game Ended',
            timestamp: 73
        },
        {
            name: 'Final Account Balance',
            timestamp: 79
        }
    ],
    liveDemo: 'https://www.ethtictactoe.com/',
    github: 'https://github.com/ashwinarora/ethereum-tictactoe'
}

export const project02 = {
    name: 'Social Network',
    images: [sn1,sn2,sn3,sn4,sn5],
    description: 'A full-fleged social networking platform. Allowing users to follow, post images, comment, like etc. A complete implementation of MERN stack with JWT authentication.',
    features: ['Follow/Unfollow', 'Make New Post', 'Like & Comment', 'Password Reset', 'Image Upload', 'Search'],
    tools: ['React', 'MongoDB', 'Express', 'JWT Authentication','Fetch API', 'Nodemailer', 'MaterializeCSS', 'REST'],
    // youtube: 'https://www.youtube.com/embed/zccVz3-4Mho?start=',
    videoId: 'yrIb_unPfec',
    timestamps: [
        {
            name: 'Sign Up/Sign In',
            timestamp: 0
        }, 
        {
            name: 'Sign In Success',
            timestamp: 18
        },
        {
            name: 'Follow/Unfollow',
            timestamp: 39
        },
        {
            name: 'Like and Comment',
            timestamp: 48,
        },
        {
            name: 'New Post',
            timestamp: 56
        },
        {
            name: 'Update Profile Image',
            timestamp: 74
        },
        {
            name: 'Delete Post',
            timestamp: 81
        },
        {
            name: 'Password Reset',
            timestamp: 88
        }
    ],
    liveDemo: 'https://socialnetwork-ashwin.herokuapp.com/',
    github: 'https://github.com/ashwinarora/mern-social-network'
}

export const project03 = {
    name: 'Ethereum Housie',
    images: [h1,h2,h3,h4,h5],
    description: 'A real-time multiplayer game inspired by Bingo. There is no limit to the number of players per game. Each player pays in ETH to purchase a ticket and then select 15 numbers(1-90). The server broadcasts random numbers one by one to the players. The first player to strike-off all the numbers in its ticket wins all the ETH.',
    features: ['Multiplayer', 'Real Time', 'Synchronized Gameplay', 'Instant Payouts', 'No Player Limit', 'Monitor Game'],
    tools: ['Socket.io', 'Web3.js', 'React', 'Node', 'Express', 'Axios', 'Infura', 'Solidity'],
    // youtube: 'https://www.youtube.com/embed/zccVz3-4Mho?start=',
    videoId: 'aN0AKmAdPjs',
    timestamps: [
        {
            name: 'Game Setup',
            timestamp: 0
        }, 
        {
            name: 'Ticket Setup',
            timestamp: 10,
        },
        {
            name: 'Ticket Purchase',
            timestamp: 22,
        },
        {
            name: 'Game Started',
            timestamp: 80,
        },
        {
            name: 'Game Ended',
            timestamp: 136
        }
    ],
    liveDemo: 'https://www.eth-housie.com/',
    github: 'https://github.com/ashwinarora/ethereum-housie'
}