import React from 'react'

function Pills(props){
    if(props.features){
        return (
            <div className="flex flex-row items-start justify-start flex-wrap w-full h-auto">
                {
                    props.features.map( (item, key) => {
                        return (
                            <span key={key} className="rounded-full bg-blue-700 px-2 mb-1 mr-1">
                                {item}
                            </span>
                        )
                    })
                }
            </div>
        )
    } else if(props.tools){
        return (
            <div className="flex flex-row items-start justify-start flex-wrap w-full h-auto">
                {
                    props.tools.map( (item, key) => {
                        return (
                            <span key={key} className="rounded-full bg-purple-800 px-2 mb-1 mr-1">
                                {item}
                            </span>
                        )
                    })
                }
            </div>
        )
    } else {
        return null
    }
}

export default Pills
