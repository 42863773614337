import React, { useState, useEffect } from 'react'
import logo from '../assets/WEBSITE LOGO.png'
function NavBar() {
    const [isExpanded, setIsExpanded] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false)

    useEffect(() => {
        const listener = document.addEventListener("scroll", e => {
            const scrolled = document.scrollingElement.scrollTop
            if (scrolled >= 60) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        })
        return () => {
            document.removeEventListener("scroll", listener)
        }
    }, [isScrolled])

    function closeMenu() {
        setIsExpanded(false)
    }

    return (
        <header className="md:fixed md:top-0 md:left-0 md:right-0 w-full h-auto z-20">
            <div className={`flex justify-center md:justify-between items-center content-center h-auto ${isScrolled ? 'bg-gray-900 bg-opacity-50' : ''}`}>
                <a href="#home" className="w-7/12 sm:w-2/5 md:w-1/4 md:ml-5 ml-auto md:py-2 lg:py-0">
                    {/* <div className=""> */}
                    <img src={logo} alt="Ashwin Arora" className="w-full h-auto" />
                    {/* </div> */}
                </a>
                <div className="md:hidden ml-auto" onClick={() => { setIsExpanded(!isExpanded) }}>
                    <svg className={`${isExpanded ? 'hidden' : 'inline'} md:hidden w-8 h-auto`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" /></svg>
                    <svg className={`${isExpanded ? 'inline' : 'hidden'} md:hidden w-8 h-auto`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                </div>
                <div className="hidden md:flex md:justify-around w-2/6 mr-6">
                    <a href="#projects"><div className="px-2 cursor-pointer font-medium text-xl hover:text-blue-500" >Projects</div></a>
                    <a href="#about"><div className="px-2 cursor-pointer font-medium text-xl hover:text-blue-500" >About</div></a>
                    <a href="#contact"><div className="px-2 cursor-pointer font-medium text-xl hover:text-blue-500" >Contact</div></a>
                    {/* <div className="py-3 px-2 cursor-pointer" >About</div>
                    <div className="py-3 px-2 cursor-pointer" >Contact</div> */}
                </div>
            </div>
            <div className={`${isExpanded ? 'flex flex-col items-end justify-between' : 'hidden'} md:hidden h-auto fixed right-0 z-20`}>
                <div className="cursor-pointer text-white font-medium text-xl z-30 my-3 mr-3"><a href="#projects" onClick={closeMenu}>Projects</a></div>
                <div className="cursor-pointer text-white font-medium text-xl z-30 my-3 mr-3"><a href="#about" onClick={closeMenu}>About</a></div>
                <div className="cursor-pointer text-white font-medium text-xl z-30 my-3 mr-3"><a href="#contact" onClick={closeMenu}>Contact</a></div>
            </div>
        </header>
    )
}

export default NavBar