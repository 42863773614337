import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import YouTube from 'react-youtube'

function ProjectPortal(props) {
    const [player, setPlayer] = useState({})
    const [showTimestamps, setShowTimestamps] = useState(false)

    function getTimeString(_timestamp) {
        const minutes = Math.floor(_timestamp / 60)
        const seconds = _timestamp - minutes * 60
        if (minutes <= 9) {
            if (seconds <= 9) {
                return `0${minutes}:0${seconds}`
            } else {
                return `0${minutes}:${seconds}`
            }
        } else {
            if (seconds <= 9) {
                return `${minutes}:0${seconds}`
            } else {
                return `${minutes}:${seconds}`
            }
        }
    }

    if (!props.isOpen) return null
    return ReactDOM.createPortal(
        <>
            <div className="fixed inset-0 bg-gray-600 z-40 opacity-50" onClick={props.close}></div>
            <div className="fixed modal-position bg-gray-900 opacity-100 z-40 w-11/12 md:w-9/12 h-auto rounded-lg">
                <button className="absolute block close-position rounded-full opacity-100 bg-red-600 hover:bg-red-900 p-0.5 text-xl" onClick={props.close}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                </button>
                <div className="flex flex-col justify-start items-start content-start">
                    <div className="w-full">
                        <YouTube className="w-full h-80" videoId={props.project.videoId} onReady={(event) => {
                            setShowTimestamps(true)
                            setPlayer(event.target)
                        }} />
                        <div className={`${!showTimestamps && 'hidden'} flex flex-wrap justify-start items-stretch text-white w-full h-auto m-2`}>
                            {
                                props.project.timestamps.map(item => (
                                    <div key={item.timestamp} className="cursor-pointer select-none rounded-full bg-blue-700 px-2 mr-2 mb-2" onClick={() => { player.seekTo(item.timestamp, true) }}>
                                        {getTimeString(item.timestamp)} {item.name}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
        , document.getElementById('portal'))
}

export default ProjectPortal
