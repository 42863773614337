import React from 'react'

function About() {
    return (
        <div id="about" className="flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 via-blue-900 to-gray-900 sm:p-16">
            <div className="flex flex-col items-center justify-evenly w-4/5 sm:w-6/12 h-auto">
                <div className="text-2xl font-bold cursor-default">
                    About Me
                </div>
                <div className="text-center p-6">
                    I am a Full Stack Developer with expertise in Blockchain. I can realize the vision of your product. I am proficient with both Front-End and Back-End Development. Using React for Front-End and Node with Express for Back-End. Apart from this, I have experience in Blockchain Development with expertise in Ethereum.
                </div>
            </div>
        </div>
    )
}

export default About