import React from 'react'
import Particles from './Particles'
import ReactTypingEffect from 'react-typing-effect'

function Home() {
    return (
        <div id="home" className="bg-gradient-to-t from-gray-900 via-blue-900 to-gray-900 relative" >
            <Particles className="fixed top-0 right-0 w-full h-full z-0" />
            <div className="absolute top-0 right-0 left-0 z-10 w-full h-screen">
                <div className="flex flex-col justify-center items-center w-full home-height">
                    <div className="flex flex-col items-center justify-center leading-none w-auto">
                        <div className="inline-grid gird-cols-1 grid-cols-xs sm:grid-cols-sm md:grid-cols-md cursor-default select-none max-w-full">
                            <div className="text-2xl">
                                Hi, I build
                            </div>
                            <div className="text-4xl sm:text-5xl md:text-6xl">
                                <ReactTypingEffect
                                    text={["Full Stack", "React", "Blockchain", "JavaScript"]}
                                    speed={150}
                                    eraseSpeed={80}
                                    eraseDelay={2200}
                                    typingDelay={500}
                                    className="bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-blue-500 font-semibold"
                                    cursorClassName="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500"
                                />
                            </div>
                            <div className="text-4xl sm:text-5xl md:text-6xl">
                                Applications
                            </div>
                            <div className="text-xl sm:text-2xl mt-2">
                                Full Stack and Blockchain Developer
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center p-0">
                    <a href="#projects">
                        <svg className="animate-bounce text-white w-32 md:w-48 h-auto cursor-pointer mt-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" /></svg>
                    </a>
                </div>
            </div>
        </div>
    )
    // return (
    //     <div id="home" className="bg-gradient-to-t from-gray-900 via-blue-900 to-gray-900 relative" >
    //         <Particles className="fixed top-0 right-0 w-full h-full z-0" />
    //         <div className="absolute top-0 right-0 left-0 z-10 w-full h-screen">
    //             <div className="flex flex-col justify-center items-center w-full home-height">
    //                 <div className="flex flex-col items-center justify-center leading-none w-auto">
    //                     <div className="inline-grid gird-cols-1 grid-cols-xs sm:grid-cols-sm md:grid-cols-md max-w-full">
    //                         <div className="text-2xl">
    //                             Hi, I am
    //                         </div>
    //                         <div className="text-4xl sm:text-5xl md:text-6xl">
    //                             Ashwin Arora
    //                         </div>
    //                         <div className="text-xl sm:text-2xl">
    //                             Blockchain and Full Stack Developer
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="flex justify-center items-center p-0">
    //                 <a href="#projects">
    //                     <svg className="animate-bounce text-white w-32 md:w-48 h-auto cursor-pointer mt-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" /></svg>
    //                 </a>
    //             </div>
    //         </div>
    //     </div>
    // )
}

export default Home
