import React, {useState, useEffect} from 'react'
import Carousel from './CarouselComponent'
import ProjectPortal from './ProjectPortal'
import Pills from './Pills'
import "react-responsive-carousel/lib/styles/carousel.min.css"


function Card(props) {
    const [modalOpen, setModelOpen] = useState(false)
    
    useEffect( () => {
        if(modalOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }, [modalOpen])

    return (
        <div className="flex flex-col md:grid md:grid-cols-2 bg-gradient-to-b from-black to-transparent rounded-r-lg w-full h-auto my-6 transform ease-in-out duration-100 hover:scale-105 cursor-default">
            <div className="md:col-span-1 flex flex-col justify-start items-center">
                <Carousel className="" images={props.project.images} />
                <div className="flex flex-row justify-evenly items-center space-x-0.5 w-full">
                    {/* <a href={props.project.liveDemo} target="_blank" className="flex-button text-center text-xl bg-orange-500 hover:bg-yellow-500 p-2 w-1/2 mb-auto">
                        <span className="hidden sm:inline">Live</span>&nbsp;Demo
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg>
                    </a> */}
                    <a href={props.project.liveDemo} target="_blank" rel="noreferrer" className="text-center text-xl bg-orange-500 hover:bg-yellow-500 p-2 w-1/2 mb-auto">
                        <span className="hidden sm:inline">Live</span>&nbsp;Demo
                        <svg className="w-6 h-6 inline ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg>
                    </a>
                    {/* <a href={props.project.github} target="_blank" className="flex-button text-center text-xl bg-orange-500 hover:bg-yellow-500 p-2 w-1/2 mb-auto">
                        <span className="hidden sm:inline">View</span>&nbsp;Source
                        <svg className="w-6 h-6 relative bottom-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" /></svg>
                    </a> */}
                    <a href={props.project.github} target="_blank" rel="noreferrer" className="text-center text-xl bg-orange-500 hover:bg-yellow-500 p-2 w-1/2 mb-auto">
                        <span className="hidden lg:inline">View</span>&nbsp;Source
                        <svg className="w-6 h-6 inline ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" /></svg>
                    </a>
                </div>
            </div>
            <div className="md:col-span-1 flex flex-col flex-grow items-start justify-start md:mx-2 mt-2 md:mt-0 w-auto h-full">
                <div className="w-full">
                    <div className="flex justify-between w-full min-w-full">
                        <div className="font-bold text-2xl">
                            {props.project.name}
                        </div>
                        <button onClick={() => setModelOpen(true)} className="flex-button max-h-10 px-2 bg-orange-500 hover:bg-yellow-500">
                            Video
                            {/* <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg> */}
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                        </button>
                        <ProjectPortal isOpen={modalOpen} close={() => setModelOpen(false)} project={props.project} />
                    </div>
                    <div className="my-2 leading-tight">
                        {props.project.description}
                    </div>
                </div>
                <div className="w-full mb-2">
                    <div className="font-bold">
                        Features
                    </div>
                    <Pills features={props.project.features} />
                </div>
                <div className="w-full mb-2">
                    <div className="font-bold">
                        Tools Used
                    </div>
                    <Pills tools={props.project.tools} />
                </div>
            </div>
        </div>
    )
    
    
}

export default Card