import React from 'react'
import Card from './Card'
import { project01, project02, project03 } from '../data/data.js'

function Projects() {
    return (
        <div id="projects" className="flex flex-col justify-evenly items-center content-center w-auto h-auto">
            <div className="text-3xl md:mt-10">Projects</div>
            <div className=" grid grid-cols-12fr sm:grid-cols-10fr md:grid-cols-6fr">
                {/* <div className="flex-grow" style={{flexBasis: '100px'}}></div> */}
                <div className="col-start-2 col-end-3">
                    {/* <img src={project01.images[0]} className="h-auto w-6/12 object-scale-down" alt=""/> */}
                    <Card project={project01} />
                    <Card project={project02} />
                    <Card project={project03} />
                </div>
                {/* <div className="flex-grow"></div> */}
            </div>
        </div>
    )
}

export default Projects
