import React from 'react';
import NavBar from './components/NavBar'
import Home from './components/Home'
import Projects from './components/Projects'
import About from './components/About'
import Contact from './components/Contact'
import Footer from './components/Footer'
// import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

function App() {
    return (
        // <Router>
            // {/* <Particles /> */}
            <div className="text-gray-300 bg-gray-900">
                <NavBar />
                <Home />
                <Projects />
                <About />
                <Contact />
                <Footer />
            </div>
        // </Router>
    );
}

export default App;