import React from 'react'
import { Carousel } from "react-responsive-carousel";

function CarouselComponent(props) {
    if(props.images){
        return (
            <Carousel className="" autoPlay showThumbs={false} infiniteLoop={true}>
                {
                    props.images.map( (item,key)=> (
                        <div key={key}>
                            <img className="w-full h-auto transform" src={item} alt=""/>
                        </div>
                    ))
                }
            </Carousel>
        )
    } else {
        return(
            <span></span>
        )
    }
}

export default CarouselComponent